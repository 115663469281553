<template>
  <div>
    <el-dialog
      v-loading="loading"
      title="项目排序"
      :visible.sync="showDialog"
      width="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span class="content-box">
        <div class="tips">
          <i class="el-icon-warning-outline"></i>
          <span>可点击 【上/下移】按钮 或 鼠标拖动上下行 进行排序</span>
        </div>

        <div class="sort-table">
          <div class="sort-item head">
            <span class="sort">序号</span>
            <span class="">编号</span>
            <span class="name">名称</span>
            <span class="">单价</span>
          </div>
          <draggable
            class="list-group"
            v-model="dataList"
            group="list"
            handle=".sort-item"
            animation="300"
            @start="onMove($event, 'start')"
            @end="onMove($event, 'end')"
          >
            <div
              class="sort-item"
              :class="selectShowGuid == item.ShowGuid ? 'active' : ''"
              v-for="(item, index) in dataList"
              :key="item.ShowGuid"
              @click="onSelectItem(item, index)"
            >
              <span class="sort">{{ index + 1 }}</span>
              <span class="">{{ item.ItemNo }}</span>
              <span class="name">{{ item.ItemName }}</span>
              <span class="">{{ item.Price }}</span>
            </div>
          </draggable>
        </div>
      </span>
      <span slot="footer" class="footer">
        <div class="sort-box">
          <el-button
            type="primary"
            size="default"
            :disabled="selectShowIndex == 0"
            @click="onSort(-1)"
            >上移</el-button
          >
          <el-button
            type="primary"
            size="default"
            :disabled="selectShowIndex == dataList.length - 1"
            @click="onSort(1)"
            >下移</el-button
          >
        </div>
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button type="primary" :disabled="loading" @click="saveShowSortBatch"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Item from "@/api/item.js";

import draggable from "vuedraggable";

export default {
  components: { draggable },

  data() {
    return {
      selectShowGuid: "",
      selectShowIndex: -1,
      dataList: [],
      submitData: {
        father_guid: "",
        item_state: "",
      },
      showDialog: false,
      loading: false,
    };
  },

  mounted() {},

  methods: {
    // 初始化项目排序
    async initSortItems() {
      try {
        let { data } = await Item.initSortItems(this.submitData);
        this.dataList = data;
      } catch (err) {
        console.log(err);
      }
    },

    // 保存项目排序
    async saveShowSortBatch() {
      let dataList = JSON.parse(JSON.stringify(this.dataList));
      this.loading = true;
      await dataList.forEach((item, index) => {
        item.Sort = index + 1;
      });
      // console.log(dataList);
      try {
        let { data, errcode } = await Item.saveShowSortBatch({
          List: dataList,
        });
        if (errcode == 0) {
          this.$emit("success");
          this.$message.success("保存成功");
          this.onCloseDialog();
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 选中项目事件
    onSelectItem(event, index) {
      this.selectShowGuid = event.ShowGuid;
      this.selectShowIndex = index;
    },

    // 排序移动事件
    onSort(num) {
      let dataList = JSON.parse(JSON.stringify(this.dataList)),
        { selectShowIndex } = this;

      let param = dataList.splice(selectShowIndex, 1);
      dataList.splice(selectShowIndex + num, 0, param[0]);
      this.selectShowIndex = selectShowIndex + num;
      this.dataList = dataList;
      // console.log(param, dataList, selectShowIndex);
    },

    // 拖拽事件
    onMove(event, type) {
      if (type == "start") {
        this.selectShowIndex = event.oldIndex;
      } else {
        this.selectShowIndex = event.newIndex;
      }
      this.selectShowGuid = this.dataList[this.selectShowIndex].ShowGuid;

      // console.log(event);
    },

    // 打开对话框
    onShowDialog(event = {}) {
      this.submitData = JSON.parse(JSON.stringify(event));
      this.initSortItems();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  .tips {
    padding-block-end: 10px;
    color: #bbb;
  }

  .sort-table {
    display: block;
    max-height: 400px;
    overflow-y: auto;
  }

  .head {
    border-top: 1px solid #ccc;
    span {
      font-weight: bold;
    }
  }
  .sort-item {
    .flex-row;

    span {
      .flex-row;
      flex: 1;
      height: 40px;
      padding: 5px 10px;
      box-sizing: border-box;
      border-left: 1px solid;
      border-bottom: 1px solid;
      border-color: #ccc;
    }

    span:last-child {
      border-right: 1px solid #ccc;
    }

    .sort {
      flex: unset;
      width: 60px;
    }

    .name {
      flex: 3;
    }
  }

  .active {
    background: rgba(0, 90, 190, 0.2);
    // color: white;
  }
}
.footer {
  .flex-row;
  justify-content: flex-end;

  .sort-box {
    margin: 0 auto;
  }
}
</style>
