<template>
  <div>
    <el-dialog
      title="移动显示组"
      :visible.sync="showDialog"
      width="400px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span class="content-box">
        <el-tree
          ref="Etree"
          :data="treeData"
          node-key="id"
          draggable
          accordion
          :default-expanded-keys="['ALL']"
          :current-node-key="fatherShowGuid"
          :highlight-current="true"
          @node-click="nodeClick"
        >
        </el-tree>
      </span>
      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button type="primary" @click="moveItemshow">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Service from "@/api/service.js";

export default {
  props: {
    treeData: {
      type: Array,
      default: () => [],
    },

    showType: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      fatherShowGuid: "",
      selfShowGuids: "",
      showDialog: false,
    };
  },

  mounted() {},

  methods: {
    // 移动显示组
    async moveItemshow() {
      try {
        let { errcode } = await Service.moveItemshow({
          SelfShowGuids: this.selfShowGuids,
          FatherShowGuid: this.fatherShowGuid,
          ShowType: this.showType,
        });
        if(errcode == 0){
            this.$message.success('移组成功')
            this.$emit('success');
            this.onCloseDialog()
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 树组件节点点击事件
    nodeClick(data, dragNode, enterNode) {
      this.fatherShowGuid = data.id;
    },

    // 打开对话框
    onShowDialog(selfShowGuids) {
      this.selfShowGuids = selfShowGuids;
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
}
</style>
